.modal-content {
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 15px;
  max-width: 90%;
  margin: 0 auto;
}

.modal-dialog {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  max-width: 600px !important;
  margin: 0 auto;
  /* position: fixed; */
}
.modal-dialog div {
  max-height: fit-content !important;
}
.modal-header,
.modal-footer {
  border: none;
}
.modal-header {
  padding: 40px 0 0 40px;
}
.modal-footer {
  justify-content: center;
}

.modal-header button {
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-footer button {
  width: 75%;
  outline: none;
  padding: 15px;
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  border: none;
  font-weight: bold;
}

.signup-form {
  width: 100%;
  padding-left: 4em;
  padding-right: 4em;
}

.input-cont {
  width: 100%;
  border: 1px solid #cfd9de;
  border-radius: 5px;
  padding: 5px;
  color: #1c9bf0;
  font-size: 10pt;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-flow: column-reverse;
}

.input-cont input {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  color: #000;
  font-size: 14pt;
}

label,
input {
  transition: all 0.2s;
  touch-action: manipulation;
}

.input-cont input:placeholder-shown + label {
  transform: translate(0, 100%) scale(1.5);
  transform-origin: left bottom;
  max-width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: text;
  color: #849099;
}

.input-cont ::-webkit-input-placeholder,
.input-cont ::placeholder {
  opacity: 0;
  transition: inherit;
}

.input-cont input:focus::-webkit-input-placeholder {
  opacity: 1;
}

.input-cont input:not(:placeholder-shown) + label,
.input-cont input:focus + label {
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  color: #1c9bf0;
}

.input-cont:focus-within {
  border: 2px solid #1c9bf0;
}

.input-cont input:autofill,
.input-cont input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.header-error {
  color: #ff5656;
  font-weight: bold;
}

.header-success {
  color: #00ba7c;
  font-weight: bold;
}

.input-error {
  border: 2px solid #ff5656;
  color: #ff5656;
}

.signup-form input:-webkit-autofill,
.signup-form input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #fff inset !important;
  -webkit-text-fill-color: #000 !important;
  color: #000;
  outline: none;
  background-image: none !important;
  box-shadow: 0 50px 0px inset #fff !important;
}

@media (max-width: 440px) {
  .modal-dialog {
    /* margin-top: calc(); */
  }
  .modal-footer button {
    width: 60%;
    padding: 10px;
  }
  .modal-body {
    width: 100%;
  }
  .modal-body div input,
  input {
    font-size: 1rem;
  }
  label {
    font-size: 80%;
  }
  .signup-form {
    padding: 0 0.5em;
  }
}

@media (prefers-color-scheme: dark) {
  .modal-header {
    padding: 30px 0 0 30px;
  }
  .modal-content {
    background-color: #000;
    color: #fff;
  }
  .modal-footer button:hover {
    background-color: #161616;
  }
  .modal-backdrop {
    background-color: rgba(72, 140, 185, 0.4);
  }

  .modal-footer button {
    border: 1px solid #536371;
    transition: background-color 0.3s ease-in-out;
  }

  .btn-close {
    filter: invert(100%) sepia(3%) saturate(0%) hue-rotate(98deg)
      brightness(119%) contrast(100%);
  }

  .input-cont {
    border: 1px solid #333639;
  }
  .input-cont input {
    color: white;
  }

  .signup-form input:-webkit-autofill,
  .signup-form input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #000 inset !important;
    -webkit-text-fill-color: #fff !important;
    color: #fff;
    outline: none;
    background-image: none !important;
    box-shadow: 0 50px 0px inset #000 !important;
  }
}
