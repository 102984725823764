.messages-header {
  width: 100%;
  padding: 5em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.messages-header:hover {
  background-color: #f8f8f8;
}

.message-recipient {
  color: #849099;
  width: 100%;
  padding: 15px;
  font-size: 10pt;
}

.message-sender {
  color: #849099;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 10pt;
}

.message-bubble {
  color: #fff;
  padding: 10px 15px;
  border-radius: 25px 25px 5px 25px;
  width: fit-content;
  max-width: 70%;
  background-color: #1d9bf0;
  font-size: 12pt;
}

.message-recipient .message-bubble {
  color: #202327;
  background-color: #eff3f4;
  border-radius: 25px 25px 25px 5px;
}

.message-form {
  padding: 10px;
  border-top: 1px solid #eff3f4;
  position: relative;
}

.message-form-input {
  width: 100%;
  display: flex;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #eff3f4;
}

.message-form-input svg {
  width: 20px;
  fill: rgb(29, 155, 240, 0.6);
  cursor: pointer;
}

.message-form input {
  width: 100%;
  outline: none;
  color: #6e7175;
  border: none;
  background-color: transparent;
}

@media (prefers-color-scheme: dark) {
  .messages-header:hover {
    background-color: #16181c;
  }
  .message-recipient .message-bubble {
    background-color: #2f3336 !important;
    color: #fff;
  }
  .message-form {
    border-top: 1px solid #2f3336;
  }
  .message-form-input {
    background-color: #202327;
  }
}
