@font-face {
  font-family: "Chirp";
  src: url("../assets/chirp/chirp_regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Chirp";
  src: url("../assets/chirp/chirp_bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Chirp Medium";
  src: url("../assets/chirp/chirp_medium.otf");
}

@font-face {
  font-family: "Chirp Heavy";
  src: url("../assets/chirp/chirp_heavy.otf");
}

body,
html {
  font-family: "Chirp", sans-serif;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}
a,
a:visited,
a:active {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.main-container {
  width: 75%;
  max-width: 1300px;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  margin: auto;
  display: flex;
  position: relative;
  z-index: 1;
}

.left-bar {
  width: 23%;
  position: relative;
  padding-top: 15px;
  border-right: 1px solid #eff3f4;
}
.middle-bar {
  width: 60%;
  max-height: 100vh;
  overflow: auto;
  border-right: 1px solid #eff3f4;
}
.right-bar,
.right-bar-other {
  width: 40%;
  height: 100%;
  overflow: auto;
  padding-left: 20px;
  position: relative;
}

.right-side {
  width: 77%;
  display: flex;
  scrollbar-width: none;
}

.content-box,
.tweet-box {
  display: flex;
  border-top: 1px solid #eff3f4;
  padding: 10px 15px;
}

.content-box {
  cursor: pointer;
}
.content-box:hover {
  background-color: #f8f8f8;
}
.modal .content-box:hover {
  background-color: transparent;
}

.content-box a,
.tweet-box a {
  color: black;
  font-weight: bold;
  text-decoration: none;
}

/* .content-box a:hover, .tweet-box a:hover {
  text-decoration: underline;
} */

.profile-icon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  padding-right: 15px;
  z-index: 2;
}

.profile-icon img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 2;
}

.content-right {
  width: 100%;
}
.content-right textarea {
  width: 100%;
  height: 50px;
  resize: none;
  overflow: hidden;
  outline: none;
  border: none;
  font-size: 16pt;
  background-color: transparent;
}

.tw-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #169cf1;
  padding: 10px;
  color: white;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.login-btn,
.signup-btn {
  padding: 5px 15px;
  outline: none;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  transition: background-color 0.3s ease-in-out;
}

.login-btn {
  background-color: #169cf1;
  border: 1px solid #8fc6f6;
}
.signup-btn {
  color: #000;
  border: none;
  background-color: #fff;
}

.login-btn:hover {
  background-color: #42a7f1;
}
.signup-btn:hover {
  background-color: #d7dbdc;
}

.loader {
  width: 30px;
  height: 30px;
  border: 4px solid #bbe4ff;
  border-bottom-color: #169cf1;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 700ms linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.blue-link a,
.blue-link {
  color: #169cf1;
  font-weight: normal;
}

@media screen and (max-width: 1200px) {
  .main-container {
    width: 100%;
  }
  .middle-bar {
    width: 70%;
  }
  .right-side {
    width: 100%;
  }
  .right-bar {
    width: 30%;
    min-width: 225px;
  }
  .right-bar-other {
    padding-right: 5px;
  }
  /* .profile-banner-top { background-size: cover } */
}

@media screen and (max-width: 750px) {
  .middle-bar {
    width: 100%;
  }
  .right-bar {
    display: none;
    max-width: fit-content;
  }
  .tw-footer {
    padding: 15px;
    justify-content: flex-start;
    align-items: center;
  }
  #tw-footer-left {
    display: none;
  }
  #tw-footer-mid {
    width: 75% !important;
    height: 100%;
    font-size: 11pt;
  }
  #tw-footer-mid h4 {
    font-size: 14pt;
  }
  #tw-footer-right {
    width: 25%;
    height: 100%;
  }
  #tw-footer-right button {
    margin-bottom: 5px;
    font-size: 10pt;
  }
}

@media screen and (max-width: 500px) {
  .tw-footer {
    padding: 0 15px 0 15px;
    white-space: normal !important;
  }
  .profile-icon img {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 440px) {
  .left-bar {
    display: flex;
    flex-direction: row;
    min-width: 100vw;
    max-height: 100px;
    position: fixed;
    bottom: 0px;
    background-color: #fff;
    z-index: 100;
    border-top: 1px solid #eff3f4;
    padding: 10px;
    transition: opacity 300ms ease-in-out;
  }

  .left-bar ul {
    width: 100%;
    height: fit-content !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .left-bar li {
    padding: 0;
    margin: 0 10px;
  }

  .middle-bar {
    padding-bottom: 60px;
  }

  .right-bar-other {
    padding-bottom: 70px;
  }
}

@media (prefers-color-scheme: dark) {
  body,
  html {
    background-color: #000;
    color: #e7e9ea;
  }
  a,
  a:visited,
  a:active {
    color: #e7e9ea;
  }
  .left-bar,
  .middle-bar {
    border-right: 1px solid #2f3336;
  }
  .content-box,
  .tweet-box {
    border-top: 1px solid #2f3336;
    color: #e7e9ea;
  }
  .content-box a,
  .tweet-box a {
    color: #e7e9ea;
  }
  .content-box:hover {
    background-color: #040404;
  }
  .content-right textarea {
    color: #e7e9ea;
  }

  .tweet-icons .blue-icon,
  .tweet-icons .pink-icon,
  .tweet-icons .green-icon {
    fill: #5e6163;
  }

  .loader {
    border: 4px solid #083653;
    border-bottom-color: #169cf1;
  }

  .left-bar {
    background-color: #000;
    border-top: 1px solid #2f3336;
  }
}
