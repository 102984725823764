.lds-ring {
  display: none;
  position: relative;
  width: 25px;
  height: 25px;
  margin-right: 30px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #169cf1 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tweet-icons {
  display: flex;
}
.tweet-icons .blue-icon,
.tweet-icons .pink-icon,
.tweet-icons .green-icon {
  padding: 5px 8px;
  border-radius: 50%;
  margin-top: 5px;
}
.blue-count {
  color: #849099;
  font-weight: normal;
}
.blue-icon:hover + .blue-count {
  color: #169cf1;
}
.green-count {
  color: #849099;
  font-weight: normal;
}
.green-icon:hover + .green-count {
  color: #00ba7c;
}
.pink-count {
  color: #849099;
  font-weight: normal;
}
.pink-icon:hover + .pink-count {
  color: #f91980;
}
.tweet-icons svg {
  width: 15px;
  height: 15px;
  margin-top: -3px;
  line-height: 15px;
}
.tweet-icons {
  font-size: 10pt;
}

#tweet.tweet-icons {
  width: 100%;
  align-items: stretch;
}
#tweet.tweet-icons > div {
  flex: 1;
}
#tweet.tweet-icons div {
  display: flex;
  justify-content: center;
  padding: 0;
}
#tweet.tweet-icons svg {
  width: 40px;
  height: 40px;
  line-height: 25px;
  padding: 10px;
  margin-top: 0;
}

.tweet-icons .blue-icon:hover {
  background-color: rgb(24, 158, 242, 0.15);
}
.tweet-icons .blue-icon:hover svg {
  filter: invert(61%) sepia(95%) saturate(4496%) hue-rotate(182deg)
    brightness(103%) contrast(90%);
}
.tweet-icons .pink-icon:hover {
  background-color: rgb(249, 25, 128, 0.15);
}
.tweet-icons .pink-icon:hover svg {
  filter: invert(48%) sepia(99%) saturate(7387%) hue-rotate(321deg)
    brightness(99%) contrast(96%);
}
.tweet-icons .green-icon:hover {
  background-color: rgb(1, 180, 120, 0.15);
}
.tweet-icons .green-icon:hover svg {
  filter: invert(41%) sepia(99%) saturate(1542%) hue-rotate(131deg)
    brightness(97%) contrast(99%);
}

.d-tweet svg {
  width: 15px;
  filter: invert(63%) sepia(12%) saturate(329%) hue-rotate(163deg)
    brightness(87%) contrast(86%);
}

.d-tweet-open {
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 5px 10px;
  border: 1px solid #b51a23;
  border-radius: 25px;
  color: #b51a23;
  font-size: 10pt;
  display: none;
}
.d-tweet-open:hover {
  background-color: #ffebec;
}

.d-tweet-open svg {
  width: 15px;
  margin: -3px 5px 0 0;
  filter: invert(15%) sepia(91%) saturate(3504%) hue-rotate(347deg)
    brightness(81%) contrast(94%);
}

@media (prefers-color-scheme: dark) {
  .d-tweet-open:hover {
    background-color: #fa8a8f;
  }
}
