.profile-banner {
  width: 100%;
  height: 266px;
  position: relative;
}

.profile-banner-top {
  width: 100%;
  height: 199.25px;
  background-repeat: no-repeat;
  background-size: contain;
}

.profile-avatar {
  position: absolute;
  bottom: 0;
  left: 20px;
  border-radius: 50%;
  border: 4px solid #fff;
  cursor: pointer;
  background-color: #fff;
}

.profile-avatar:hover + .overlay {
  width: 133.5px;
  height: 133.5px;
  position: absolute;
  bottom: 4px;
  left: 24px;
  background-color: #000;
  border-radius: 50%;
  opacity: 10%;
  -webkit-backface-visibility: hidden;
}

.profile-avatar img {
  border-radius: 50%;
  width: 133.5px;
}

.profile-bottom {
  width: 100%;
  height: 66.75px;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.profile-bottom svg {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 20px;
}

.prof-button {
  width: 25px;
  height: 25px;
  border: 1px solid #e4e9eb;
  border-radius: 50%;
  padding: 20px;
  position: relative;
  cursor: pointer;
}

.prof-button:hover {
  background-color: #eaeaea;
}

@media (prefers-color-scheme: dark) {
  .profile-avatar {
    border: 4px solid #000;
    background-color: #000;
  }

  .profile-bottom svg {
    filter: invert(97%) sepia(100%) saturate(2%) hue-rotate(266deg)
      brightness(112%) contrast(100%);
  }
  .prof-button {
    border: 1px solid #2b3135;
  }
  .prof-button:hover {
    background-color: #151515;
  }
}

@media screen and (max-width: 500px) {
  .profile-banner-top {
    height: 15vh;
    background-size: cover;
  }
  .profile-banner {
    height: 19.5vh;
  }
  .profile-avatar {
    border: 3px solid #fff;
    bottom: 0;
  }
  .profile-avatar img {
    width: 60px;
    height: 60px;
  }
  .profile-bottom {
    height: fit-content;
    padding: 0;
  }
  .overlay {
    display: none;
  }
  .prof-button {
    margin: 10px;
  }
}

@media screen and (max-width: 500px) and (prefers-color-scheme: dark) {
  .profile-avatar {
    border: 3px solid #000;
  }
}
