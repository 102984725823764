.settings-middle-bar { width: 40% !important }
.right-bar-other { width: 60% !important }

.settings-middle-bar a { font-weight: normal }
.settings-middle-bar a:hover { text-decoration: none }

.settings-middle-bar ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.settings-middle-bar li { padding: 15px }
.settings-middle-bar li:hover { background-color: #F8F8F8 }

@media (prefers-color-scheme: dark) {
  .settings-middle-bar li:hover { background-color: #16181C }
}