.tw-logo {
  width: fit-content;
  padding: 15px;
  border-radius: 50%;
}

.tw-logo img {
  margin-left: 25px;
}

.tw-logo:hover {
  background-color: rgb(24, 158, 242, 0.15);
}
.nav-list a,
.nav-list a:hover {
  color: black;
  text-decoration: none;
  outline: none;
  border: none;
}
.nav-list svg,
.nav-list img {
  width: 25px;
  margin: -5px 10px 0 0;
}
.nav-list {
  margin-top: 15px;
  list-style: none;
}

.nav-list li {
  width: fit-content;
  padding: 10px 30px 10px 10px;
  margin-top: 10px;
  font-size: 16pt;
}

.nav-list li:hover {
  background-color: #e7eaeb;
  border-radius: 75px;
}

.nav-list-active {
  font-weight: bold;
}

.tw-blue-btn-lg,
.tw-blue-btn-sm {
  width: 75%;
  padding: 12px;
  background-color: #169cf1;
  outline: none;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  font-size: 13pt;
}

.tw-blue-btn-sm {
  width: fit-content;
  padding: 7px 12px;
  font-size: 11pt;
}

.nav-user-info-cont {
  width: 100%;
  position: absolute;
  bottom: 50px;
  padding: 5px;
}

.nav-user-info {
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  white-space: nowrap;
  cursor: pointer;
}

.nav-user-info:hover {
  background-color: #e4e9eb;
}
.nav-user-more svg {
  width: 20px;
  float: right;
  margin-right: 5px;
}

.nav-user-open {
  display: none;
  width: 90%;
  padding: 20px;
  border-radius: 25px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px #e0e0e0;
  cursor: pointer;
  background-color: #fff;
  z-index: 10;
}

.nav-user-open:hover {
  background-color: #e4e9eb;
}

@media (prefers-color-scheme: dark) {
  .tw-logo {
    margin: 0;
  }
  #x-logo {
    filter: brightness(0) invert(1);
  }
  .nav-list a,
  .nav-list a:hover {
    color: #e7e9ea;
  }
  .nav-list li:hover {
    background-color: #161616;
  }
  .nav-list svg,
  .nav-list img {
    filter: invert(100%) sepia(0%) saturate(3601%) hue-rotate(251deg)
      brightness(116%) contrast(100%);
  }

  .nav-user-info:hover {
    background-color: #181818;
  }

  .nav-user-more svg {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
      brightness(102%) contrast(102%);
  }

  .nav-user-open {
    border: 1px solid #1d1d1d;
    box-shadow: 0 0 10px #727272;
    background-color: #000;
    opacity: 100% !important;
  }

  .nav-user-open:hover {
    background-color: #151515;
  }
}

@media screen and (max-width: 1200px) {
  .nav-title {
    display: none;
  }
  .tw-blue-btn-lg {
    display: none;
  }
  .left-bar {
    width: fit-content !important;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .left-bar ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: fit-content;
  }
  .nav-list svg,
  .nav-list img {
    margin: 0;
  }
  .nav-list li {
    padding: 10px 15px;
  }
  #nav-user-info,
  .nav-user-more svg {
    display: none;
  }
  .nav-user-info {
    background-color: transparent !important;
    padding: 0;
  }
  .nav-user-open {
    width: fit-content;
    position: fixed;
    bottom: 100px;
    padding: 10px;
  }
}

@media screen and (max-width: 500px) {
  /* .nav-user-info-cont { position: fixed; left: 5px; bottom: 25px }
  .nav-user-open { position: fixed; left: 10px; bottom:  70px } */
  .nav-user-info {
    position: fixed;
    bottom: 60px;
  }
}

@media screen and (max-width: 1200px) {
  .tw-logo img {
    margin-left: 0;
  }
}

@media screen and (max-width: 440px) {
  /* .nav-list li,
  .tw-logo {
    padding: 15px 10px;
  } */

  .tw-logo,
  .tw-logo img {
    margin: 0 15px;
    padding: 0;
  }

  .tw-logo:hover {
    background-color: transparent;
  }

  .nav-list {
    margin: 0;
  }

  .nav-list li:hover,
  .nav-list li:active {
    background-color: transparent;
  }

  .nav-user-info,
  .nav-user-info-cont {
    /* display: none !important; */
    position: relative;
    top: auto;
    bottom: auto;
    width: fit-content;
    margin: 0;
    padding: 0;
    padding-left: 10px;
  }

  .nav-user-open {
    width: fit-content;
    position: fixed;
    /* bottom: 100px; */
    right: 50px;
    bottom: 3em;
    padding: 10px;
  }

  .nav-tweet-btn {
    display: none !important;
  }
}
